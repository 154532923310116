import React from "react";
import { ProjectCareer } from "../../data/Projects";
import Section from "../../components/Section";
import CardContainer from "../../components/CardContainer";
import Card from "../../components/Card";
import CareerImages from "../../assets/CareerImages";
import LoadingCard from "../../components/LoadingCard";

type Props = {
    careers: ProjectCareer[],
    onSelect: (id:number) => void
}

const CareerSelector : React.FC<Props> = ({careers, onSelect}) => {
    const [filteredCareers, setFilteredCareers] = React.useState<ProjectCareer[]>(careers);
    const [filterString, setFilterString] = React.useState<string>("");
    React.useEffect(() => {
        if (filterString === "") {
            setFilteredCareers(careers);
        } else {
            setFilteredCareers(careers.filter(c => 
                c.name.toLocaleLowerCase().match(`.*${filterString.toLocaleLowerCase()}.*`)));
        }
    }, [filterString, careers]);
    return <React.Fragment>
        <Section>
            <p className="p font-semibold">Choose from over 110 careers fields below to get started on your Virtual Work Experiences project.</p>
            <input 
                className="form-input w-full" 
                placeholder="Search for your career" 
                value={filterString} 
                onChange={({currentTarget:{value}})=>setFilterString(value)} />
        </Section>
        <Section>
            <CardContainer>
                {careers.length === 0 && <React.Fragment>
                    <LoadingCard />
                    <LoadingCard />
                    <LoadingCard />
                    <LoadingCard />
                    <LoadingCard />
                    <LoadingCard />
                    <LoadingCard />
                    <LoadingCard />
                    <LoadingCard />
                </React.Fragment>}
                {filteredCareers.map((c, i) => 
                    <Card 
                        image={CareerImages[c.id]?.primary ?? undefined} 
                        title={c.name} key={`career-${i}`} 
                        buttonText="Get Started"
                onClick={()=>onSelect(c.id)} />)}
            </CardContainer>
            {careers.length && filteredCareers.length === 0 && <div><h4 className="h4">No careers found for &quot;{filterString}&quot;</h4></div>}
        </Section>
    </React.Fragment>
}

export default CareerSelector;