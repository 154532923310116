import { IconX } from "@tabler/icons-react";
import React, { PropsWithChildren } from "react";
import ReactModal from "react-modal";
import ReactPlayer from 'react-player'

interface ModalProps {
    vimeoId: string,
    show: boolean,
    onHide: ()=>void,
}

const VideoModal : React.FC<PropsWithChildren & ModalProps> = ({vimeoId, show, onHide, children}) =>
    <ReactModal
        className={"bg-transparent"}
        overlayClassName={"fixed inset-0 z-50 bg-black bg-opacity-75 transition-opacity"}
        isOpen={show} 
        onRequestClose={onHide}>
        <div className="absolute mt-8 w-full ">
            <div className="flex justify-center gap-2">
                <ReactPlayer width={"90vw"} height={"85vh"} playing url={`https://vimeo.com/${vimeoId}`} controls />
                <IconX height={30} width={30} className="cursor-pointer" color="white" onClick={onHide} />
            </div>
            {children}
        </div>

    </ReactModal>

export default VideoModal;