import React from "react";
import { Project } from "../../data/Projects";
import Button from "../../components/Button";
import { usePDF } from "@react-pdf/renderer";
import generateCertificate from "./Certificate";
import genereateReference from "./Reference";

const CertificateForm : React.FC<{project:Project, image:string}> = ({project, image}) => {
    const [certificatePdf, updateCertificatePdf] = usePDF();
    const [referencePdf, updateReferencePdf] = usePDF();
    const [selectedItems, setSelectedItems] = React.useState<string[]>([]);
    const [name, setName] = React.useState<string>("");
    const toggleText = (text:string) => setSelectedItems(i => {
        if (selectedItems.includes(text)) {
            return i.filter(t => t !== text)
        } else {
            return i.concat([text])
        }
    });
    React.useEffect(() => {
        const cert = generateCertificate(name, project.careerName, selectedItems);
        const ref = genereateReference(name, selectedItems);
        updateCertificatePdf(cert);
        updateReferencePdf(ref)
    }, [name, selectedItems, updateCertificatePdf, updateReferencePdf, project])
    const downloadCertificate = () => {
        if (name !== "" && selectedItems.length > 0) {
            certificatePdf.url && window.open(certificatePdf.url);
        }
    }
    const downloadReference = () => {
        if (name !== "" && selectedItems.length > 0) {
            referencePdf.url && window.open(referencePdf.url);
        }
    }
    return <div className="w-full">
        <article className="relative max-w-md mx-auto md:max-w-none">
            <figure className="md:absolute md:inset-y-0 md:right-0 md:w-1/2">
                <img className="w-full h-full object-cover" src={image} width="516" height="387" alt={project.careerName} />
            </figure>
            <div className="relative bg-gray-800 py-8 md:py-16 px-6 md:pr-16 md:max-w-lg lg:max-w-xl text-left">
                <h4 className="h4 mb-4">In doing my Virtual Work Experiences project I...</h4>
                {project.pastText.map((t, i) =>
                    <div className="w-full px-3 mb-2" key={`cert-form-${i}`}>
                        <label className="flex items-center">
                            <input type="checkbox" className="form-checkbox" 
                                onClick={()=>toggleText(t.text)} checked={selectedItems.includes(t.text)} />
                            <span className="ml-2">{t.text}</span>
                        </label>
                    </div>
                )}
                <div className="mt-4 border-t border-t-gray-600 pt-2">
                    <strong>Please enter your name to be displayed on your certificate:</strong>
                </div>
                <div>
                    <input type="text" className="form-input w-full mt-2" value={name} onChange={({currentTarget:{value}})=>setName(value)} />
                </div>
                <div className="flex gap-2">
                    <Button disabled={name === "" || selectedItems.length === 0} className="mt-6" onClick={downloadCertificate}>Get My Certificate</Button>
                    <Button disabled={name === "" || selectedItems.length === 0} className="mt-6" onClick={downloadReference}>Get My Reference</Button>
                </div>
            </div>
        </article>
    </div>    
}

export default CertificateForm;