const CareerImages : {[k:number]:{primary:string, secondary:string}} = {
    20 : {
        "primary": require("./career/accountant.jpg"),
        "secondary": require("./career/accountant-1.jpg"),
    },
    21 : {
        "primary": require("./career/acting.jpg"),
        "secondary": require("./career/acting-1.jpg"),
    },
    63 : {
        "primary": require("./career/aeronautical-eng.jpg"),
        "secondary": require("./career/aeronautical-eng-1.jpg"),
    },
    0 : {
        "primary": require("./career/air-traffic-control.jpg"),
        "secondary": require("./career/air-traffic-control-1.jpg"),
    },
    64 : {
        "primary": require("./career/animal-attendant.jpg"),
        "secondary": require("./career/animal-attendant-1.jpg"),
    },
    22 : {
        "primary": require("./career/animator.jpg"),
        "secondary": require("./career/animator-1.jpg"),
    },
    66 : {
        "primary": require("./career/arrcheologist.jpg"),
        "secondary": require("./career/arrcheologist-1.jpg"),
    },
    1 : {
        "primary": require("./career/architecture.jpg"),
        "secondary": require("./career/architecture-1.jpg"),
    },
    67 : {
        "primary": require("./career/architecture-draft.jpg"),
        "secondary": require("./career/architecture-draft-1.jpg"),
    },
    23 : {
        "primary": require("./career/soilder.jpg"),
        "secondary": require("./career/soilder-1.jpg"),
    },
    128 : {
        "primary": require("./career/artist.jpg"),
        "secondary": require("./career/artist-1.jpg"),
    },
    24 : {
        "primary": require("./career/astronomer.jpg"),
        "secondary": require("./career/astronomer-1.jpg"),
    },
    25 : {
        "primary": require("./career/bar.jpg"),
        "secondary": require("./career/bar-1.jpg"),
    },
    26 : {
        "primary": require("./career/beauty.jpg"),
        "secondary": require("./career/beauty-1.jpg"),
    },
    27 : {
        "primary": require("./career/bricklayer.jpg"),
        "secondary": require("./career/bricklayer-1.jpg"),
    },
    28 : {
        "primary": require("./career/builder.jpg"),
        "secondary": require("./career/builder-1.jpg"),
    },
    29 : {
        "primary": require("./career/cabinet-maker.jpg"),
        "secondary": require("./career/cabinet-maker-1.jpg"),
    },
    111 : {
        "primary": require("./career/camera-operator.jpg"),
        "secondary": require("./career/camera-operator-1.jpg"),
    },
    2 : {
        "primary": require("./career/carpenter.jpg"),
        "secondary": require("./career/carpenter-1.jpg"),
    },
    3 : {
        "primary": require("./career/chef.jpg"),
        "secondary": require("./career/chef-1.jpg"),
    },
    4 : {
        "primary": require("./career/child-care.jpg"),
        "secondary": require("./career/child-care-1.jpg"),
    },
    71 : {
        "primary": require("./career/chiro.jpg"),
        "secondary": require("./career/chiro-1.jpg"),
    },
    5 : {
        "primary": require("./career/civ-eng.jpg"),
        "secondary": require("./career/civ-eng-1.jpg"),
    },
    80 : {
        "primary": require("./career/it.jpg"),
        "secondary": require("./career/it-1.jpg"),
    },
    101 : {
        "primary": require("./career/concierge.jpg"),
        "secondary": require("./career/concierge-1.jpg"),
    },
    121 : {
        "primary": require("./career/councillor.jpg"),
        "secondary": require("./career/councillor-1.jpg"),
    },
    30 : {
        "primary": require("./career/criminologist.jpg"),
        "secondary": require("./career/criminologist-1.jpg"),
    },
    112 : {
        "primary": require("./career/dance.jpg"),
        "secondary": require("./career/dance-1.jpg"),
    },
    31 : {
        "primary": require("./career/dental-tech.jpg"),
        "secondary": require("./career/dental-tech-1.jpg"),
    },
    77 : {
        "primary": require("./career/dental.jpg"),
        "secondary": require("./career/dental-1.jpg"),
    },
    32 : {
        "primary": require("./career/diesel-mech.jpg"),
        "secondary": require("./career/diesel-mech-1.jpg"),
    },
    6 : {
        "primary": require("./career/dietitian.jpg"),
        "secondary": require("./career/dietitian-1.jpg"),
    },
    131 : {
        "primary": require("./career/digial-media.jpg"),
        "secondary": require("./career/digial-media-1.jpg"),
    },
    34 : {
        "primary": require("./career/electronic-eng.jpg"),
        "secondary": require("./career/electronic-eng-1.jpg"),
    },
    33 : {
        "primary": require("./career/electrician.jpg"),
        "secondary": require("./career/electrician-1.jpg"),
    },
    35 : {
        "primary": require("./career/env.jpg"),
        "secondary": require("./career/env-1.jpg"),
    },
    36 : {
        "primary": require("./career/event.jpg"),
        "secondary": require("./career/event-1.jpg"),
    },
    127 : {
        "primary": require("./career/farming.jpg"),
        "secondary": require("./career/farming-1.jpg"),
    },
    7 : {
        "primary": require("./career/fashion.jpg"),
        "secondary": require("./career/fashion-1.jpg"),
    },
    37 : {
        "primary": require("./career/film-director.jpg"),
        "secondary": require("./career/film-director-1.jpg"),
    },
    8 : {
        "primary": require("./career/fire-fighter.jpg"),
        "secondary": require("./career/fire-fighter-1.jpg"),
    },
    9 : {
        "primary": require("./career/fitness.jpg"),
        "secondary": require("./career/fitness-1.jpg"),
    },
    122 : {
        "primary": require("./career/flight-attendant.jpg"),
        "secondary": require("./career/flight-attendant-1.jpg"),
    },
    38 : {
        "primary": require("./career/forensic.jpg"),
        "secondary": require("./career/forensic-1.jpg"),
    },
    113 : {
        "primary": require("./career/freight.jpg"),
        "secondary": require("./career/freight-1.jpg"),
    },
    40 : {
        "primary": require("./career/games-dev.jpg"),
        "secondary": require("./career/games-dev-1.jpg"),
    },
    41 : {
        "primary": require("./career/graphic.jpg"),
        "secondary": require("./career/graphic-1.jpg"),
    },
    10 : {
        "primary": require("./career/hair-dresser.jpg"),
        "secondary": require("./career/hair-dresser-1.jpg"),
    },
    102 : {
        "primary": require("./career/historian.jpg"),
        "secondary": require("./career/historian-1.jpg"),
    },
    79 : {
        "primary": require("./career/hotel-manager.jpg"),
        "secondary": require("./career/hotel-manager-1.jpg"),
    },
    103 : {
        "primary": require("./career/industrial-design.jpg"),
        "secondary": require("./career/industrial-design-1.jpg"),
    },
    42 : {
        "primary": require("./career/interior-design.jpg"),
        "secondary": require("./career/interior-design-1.jpg"),
    },
    43 : {
        "primary": require("./career/journalist.jpg"),
        "secondary": require("./career/journalist-1.jpg"),
    },
    83 : {
        "primary": require("./career/latb-tech.jpg"),
        "secondary": require("./career/latb-tech-1.jpg"),
    },
    11 : {
        "primary": require("./career/landscape.jpg"),
        "secondary": require("./career/landscape-1.jpg"),
    },
    124 : {
        "primary": require("./career/law-clerk.jpg"),
        "secondary": require("./career/law-clerk-1.jpg"),
    },
    118 : {
        "primary": require("./career/lawyer.jpg"),
        "secondary": require("./career/lawyer-1.jpg"),
    },
    114 : {
        "primary": require("./career/locksmit.jpg"),
        "secondary": require("./career/locksmit-1.jpg"),
    },
    87 : {
        "primary": require("./career/marketing.jpg"),
        "secondary": require("./career/marketing-1.jpg"),
    },
    12 : {
        "primary": require("./career/mechanic.jpg"),
        "secondary": require("./career/mechanic-1.jpg"),
    },
    44 : {
        "primary": require("./career/mechanical-eng.jpg"),
        "secondary": require("./career/mechanical-eng-1.jpg"),
    },
    45 : {
        "primary": require("./career/mechatronic-eng.jpg"),
        "secondary": require("./career/mechatronic-eng-1.jpg"),
    },
    88 : {
        "primary": require("./career/medical-sci.jpg"),
        "secondary": require("./career/medical-sci-1.jpg"),
    },
    39 : {
        "primary": require("./career/gp.jpg"),
        "secondary": require("./career/gp-1.jpg"),
    },
    46 : {
        "primary": require("./career/metal-fab.jpg"),
        "secondary": require("./career/metal-fab-1.jpg"),
    },
    47 : {
        "primary": require("./career/midwife.jpg"),
        "secondary": require("./career/midwife-1.jpg"),
    },
    126 : {
        "primary": require("./career/mining.jpg"),
        "secondary": require("./career/mining-1.jpg"),
    },
    104 : {
        "primary": require("./career/museum.jpg"),
        "secondary": require("./career/museum-1.jpg"),
    },
    105 : {
        "primary": require("./career/musician.jpg"),
        "secondary": require("./career/musician-1.jpg"),
    },
    48 : {
        "primary": require("./career/navy.jpg"),
        "secondary": require("./career/navy-1.jpg"),
    },
    13 : {
        "primary": require("./career/nurse-enrolled.jpg"),
        "secondary": require("./career/nurse-enrolled-1.jpg"),
    },
    132 : {
        "primary": require("./career/nurse.jpg"),
        "secondary": require("./career/nurse-1.jpg"),
    },
    49 : {
        "primary": require("./career/occ-therapy.jpg"),
        "secondary": require("./career/occ-therapy-1.jpg"),
    },
    123 : {
        "primary": require("./career/office-clerk.jpg"),
        "secondary": require("./career/office-clerk-1.jpg"),
    },
    90 : {
        "primary": require("./career/optomotrist.jpg"),
        "secondary": require("./career/optomotrist-1.jpg"),
    },
    50 : {
        "primary": require("./career/panel-beater.jpg"),
        "secondary": require("./career/panel-beater-1.jpg"),
    },
    14 : {
        "primary": require("./career/paramedic.jpg"),
        "secondary": require("./career/paramedic-1.jpg"),
    },
    51 : {
        "primary": require("./career/park-ranger.jpg"),
        "secondary": require("./career/park-ranger-1.jpg"),
    },
    106 : {
        "primary": require("./career/pet-groomer.jpg"),
        "secondary": require("./career/pet-groomer-1.jpg"),
    },
    92 : {
        "primary": require("./career/pharmacist.jpg"),
        "secondary": require("./career/pharmacist-1.jpg"),
    },
    52 : {
        "primary": require("./career/photographer.jpg"),
        "secondary": require("./career/photographer-1.jpg"),
    },
    15 : {
        "primary": require("./career/physio.jpg"),
        "secondary": require("./career/physio-1.jpg"),
    },
    53 : {
        "primary": require("./career/pilot.jpg"),
        "secondary": require("./career/pilot-1.jpg"),
    },
    17 : {
        "primary": require("./career/plumber.jpg"),
        "secondary": require("./career/plumber-1.jpg"),
    },
    16 : {
        "primary": require("./career/police.jpg"),
        "secondary": require("./career/police-1.jpg"),
    },
    116 : {
        "primary": require("./career/primary-teacher.jpg"),
        "secondary": require("./career/primary-teacher-1.jpg"),
    },
    54 : {
        "primary": require("./career/printer.jpg"),
        "secondary": require("./career/printer-1.jpg"),
    },
    130 : {
        "primary": require("./career/psych.jpg"),
        "secondary": require("./career/psych-1.jpg"),
    },
    107 : {
        "primary": require("./career/radiographer.jpg"),
        "secondary": require("./career/radiographer-1.jpg"),
    },
    55 : {
        "primary": require("./career/real-estate.jpg"),
        "secondary": require("./career/real-estate-1.jpg"),
    },
    56 : {
        "primary": require("./career/refridgeration-mech.jpg"),
        "secondary": require("./career/refridgeration-mech-1.jpg"),
    },
    18 : {
        "primary": require("./career/retail.jpg"),
        "secondary": require("./career/retail-1.jpg"),
    },
    119 : {
        "primary": require("./career/secondary-teacher.jpg"),
        "secondary": require("./career/secondary-teacher-1.jpg"),
    },
    95 : {
        "primary": require("./career/security.jpg"),
        "secondary": require("./career/security-1.jpg"),
    },
    58 : {
        "primary": require("./career/shop-fitter.jpg"),
        "secondary": require("./career/shop-fitter-1.jpg"),
    },
    108 : {
        "primary": require("./career/sign-writer.jpg"),
        "secondary": require("./career/sign-writer-1.jpg"),
    },
    59 : {
        "primary": require("./career/social-worker.jpg"),
        "secondary": require("./career/social-worker-1.jpg"),
    },
    60 : {
        "primary": require("./career/sound-tech.jpg"),
        "secondary": require("./career/sound-tech-1.jpg"),
    },
    100 : {
        "primary": require("./career/speech-path.jpg"),
        "secondary": require("./career/speech-path-1.jpg"),
    },
    133 : {
        "primary": require("./career/sport.jpg"),
        "secondary": require("./career/sport-1.jpg"),
    },
    97 : {
        "primary": require("./career/surveyor.jpg"),
        "secondary": require("./career/surveyor-1.jpg"),
    },
    109 : {
        "primary": require("./career/tiler.jpg"),
        "secondary": require("./career/tiler-1.jpg"),
    },
    98 : {
        "primary": require("./career/tool-making.jpg"),
        "secondary": require("./career/tool-making-1.jpg"),
    },
    110 : {
        "primary": require("./career/town-planner.jpg"),
        "secondary": require("./career/town-planner-1.jpg"),
    },
    82 : {
        "primary": require("./career/translator.jpg"),
        "secondary": require("./career/translator-1.jpg"),
    },
    120 : {
        "primary": require("./career/travel-agent.jpg"),
        "secondary": require("./career/travel-agent-1.jpg"),
    },
    61 : {
        "primary": require("./career/vet.jpg"),
        "secondary": require("./career/vet-1.jpg"),
    },
    19 : {
        "primary": require("./career/vet-nurse.jpg"),
        "secondary": require("./career/vet-nurse-1.jpg"),
    },
    115 : {
        "primary": require("./career/web-design.jpg"),
        "secondary": require("./career/web-design-1.jpg"),
    },
    129 : {
        "primary": require("./career/writer.jpg"),
        "secondary": require("./career/writer-1.jpg"),
    },
    62 : {
        "primary": require("./career/zoo.jpg"),
        "secondary": require("./career/zoo-1.jpg"),
    }
} 

export default CareerImages;