import React from "react";
import Section, { SectionWithImage } from "../../components/Section";
import LayoutWithHero from "../../layout/LayoutWithHero";
import ReactPlayer from "react-player";
import { ImageWrapper } from "../homepage";

const CareersAdvisersPage = () => 
    <LayoutWithHero title="Teacher Resources" subtitle={"Resources to help you use Virtual Work Experiences with students and parents."}>
        <Section>
            <h3 className="h3 mt-8 mb-4">Watch the Webinar</h3>
            <div className="relative flex justify-center items-center">
                <ReactPlayer width={"1024px"} height={"576px"} url={`https://vimeo.com/923911386`} controls />
            </div>
        </Section>
        <Section>
            <h3 className="h3 mb-16">Other Resources</h3>
            <SectionWithImage noBorder image={<ImageWrapper imgUrl={require("../../assets/homepage/parent.jpg")} alt="For Parents" />}>
                <ul className="list-disc">
                    <li><a className="underline text-purple-600" href={require("../../assets/letter.docx")}>Letter for Parents</a> - customise and send this letter to your parents to get them involved in Virtual Work Experiences with your students.</li>
                    <li><a className="underline text-purple-600" href={require("../../assets/worksheet.pdf")}>Student Worksheet</a> - a worksheet for students to keep track of the steps in their project</li>
                </ul>
            </SectionWithImage>
        </Section>

    </LayoutWithHero>

export default CareersAdvisersPage;