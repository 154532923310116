import React from "react";
import ProjectAPI, { Project } from "../../data/Projects";
import LayoutWithImageBand from "../../layout/LayoutWithImageBand";
import LayoutWithHero from "../../layout/LayoutWithHero";
import Section from "../../components/Section";
import Timeline from "../../components/Timeline";
import CareerImages from "../../assets/CareerImages";
import CertificateForm from "./CertificateForm";
import { ProjectModificationSuggestions, ProjectModificationSuggestionsToString, modificationSuggetions } from "./AdditionalProjectData";
import Button from "../../components/Button";
import { IconBulb } from "@tabler/icons-react";
import VideoModal from "../../components/VideoModal";

type Props = {
    careerId: number
}

const ProjectDetails : React.FC<Props> = ({careerId}) => {
    const [project, setProject] = React.useState<Project>();
    const [showModification, setShowModification] = React.useState<ProjectModificationSuggestions>();
    const [showVideoModal, setShowVideoModal] = React.useState(false);
    React.useEffect(() => {
        ProjectAPI.project(careerId).then(setProject);
    }, [careerId]);
    if (!project) {
        return <LayoutWithHero title="" />
    }
    return <LayoutWithImageBand heroImageSrc={CareerImages[careerId].primary} title={project.careerName}>
        <Section>
            <h2 className="h2 mb-8">Your Activity</h2>
            <p>{project.title} Follow as many of the steps below as you can. These are real skills for your chosen career that will impress a future employer.</p>
        </Section>
        <Section className="text-center">
            <p className="p">Download your worksheet <a className="underline text-purple-600" href={require("../../assets/worksheet.pdf")}>here</a></p>
        </Section>
        <Section>
            <div className="bg-gray-800 py-2 md:py-4 px-6 md:pr-16 flex gap-5 justify-center items-center">
                <div><IconBulb /></div>
                <div>Not sure where to start? Want more ideas?</div>
                <div><Button onClick={()=>setShowVideoModal(true)}>Watch The Video</Button></div>
            </div>
        </Section>
        <Section>
            <Timeline items={project.currentText.map(c => c.text)} />
        </Section>

        {modificationSuggetions[careerId] && <Section>
            <h4 className="h4">This activity can be modified for people with:</h4>
            <div className="flex gap-4 justify-center">
                {Object.keys(modificationSuggetions[careerId]).map(k =>
                    <Button className="mt-6" onClick={()=>setShowModification(k as ProjectModificationSuggestions)}>
                        {ProjectModificationSuggestionsToString[k as ProjectModificationSuggestions]}
                    </Button>
                )}
            </div>
        </Section>}
        {showModification && <Section>
            <div className="bg-gray-800 py-8 md:py-16 px-6 md:pr-16 text-left">
                {modificationSuggetions[careerId][showModification]}
            </div>
        </Section>}
        <Section border>
            <h2 className="h2">Finished Your Virtual Work Experiences? - Get Your Certificate!</h2>
        </Section>
        <Section>
            <CertificateForm project={project} image={CareerImages[careerId].secondary} />
        </Section>
        <VideoModal onHide={()=>setShowVideoModal(false)} show={showVideoModal} vimeoId="906974584" />
    </LayoutWithImageBand>
}

export default ProjectDetails;