import React, { PropsWithChildren } from "react";
import Menu from "../Menu";
import Footer from "../Footer";

type Props = {
    title:string, 
    subtitle?:string, 
    heroImageSrc:string
}

const LayoutWithImageBand : React.FC<PropsWithChildren<Props>> = ({children, title, heroImageSrc, subtitle}) => {
    React.useEffect(() => { window.scrollTo(0, 0) }, []);
    return <div className="flex flex-col min-h-screen overflow-hidden">
        <Menu />
        <main className="grow">
            <section className="relative sm:h-80 md:h-120">
                <div className="absolute inset-0">
                    <img className="w-full h-full object-cover" src={heroImageSrc} width="1440" height="500" alt={title} />
                    <div className="absolute inset-0 bg-gray-900 opacity-75" aria-hidden="true"></div>
                </div>
                <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
                    <div className="pt-24 pb-12 md:pt-72 md:pb-20">
                        <div className="max-w-3xl mx-auto text-center">
                            <h1 className="h1 mb-4 aos-init aos-animate" data-aos="fade-up">{title}</h1>
                            {subtitle && <p className="text-xl text-gray-400 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">{subtitle}</p>}
                        </div>
                    </div>
                </div>
            </section>
            <div className="max-w-6xl mx-auto px-4 sm:px-6 relative pt-16">
                {children}
            </div>
        </main>
        <Footer />
    </div>
}

export default LayoutWithImageBand;