import React from "react";
import { Link } from "react-router-dom";

type MenuItems = {
    title: string,
    link?: string
    externalLink?: string
} 

export const menuItems : MenuItems[] = [
    {
        title: "Experiences",
        link: "/experiences"
    },
    {
        title: "Videos",
        link: "/videos"
    },
    {
        title: "Teachers",
        link: "/teachers"
    },
    {
        title: "About Us",
        link: "/about-us"
    },
]

export const Logo : React.FC = () =>
    <div className="shrink-0 mr-4"><img height={32} src={require("../../assets/logo.svg").default} alt="Virtual Work Experiences" /></div>

const Menu : React.FC = () => {
    const [showMenu, setShowMenu] = React.useState(false);
    return <header className="absolute w-full z-30">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="flex items-center justify-between h-20">
                <div className="shrink-0 mr-4">
                    <Link className="block" to="/" aria-label="Cruip">
                        <Logo />
                    </Link>
                </div>
                <nav className="hidden md:flex md:grow">
                    <ul className="flex grow justify-end flex-wrap items-center">
                        {menuItems.map((m, i) => 
                            <li key={`menu-${i}`}>
                                {m.link && <Link className="text-gray-300 font-semibold hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out" to={m.link}>{m.title}</Link>}
                                {m.externalLink && <a className="text-gray-300 font-semibold hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out" href={m.externalLink}>{m.title}</a>}
                            </li>
                        )}
                    </ul>
                </nav>
                <div className="md:hidden">
                    <button className="hamburger" onClick={()=>setShowMenu(b=>!b)}>
                        <span className="sr-only">Menu</span>
                        <svg className="w-6 h-6 fill-current text-gray-300 hover:text-gray-200 transition duration-150 ease-in-out" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <rect y="4" width="24" height="2" rx="1"></rect>
                            <rect y="11" width="24" height="2" rx="1"></rect>
                            <rect y="18" width="24" height="2" rx="1"></rect>
                        </svg>
                    </button>
                    <nav id="mobile-nav" className={`absolute top-full z-20 left-0 w-full px-4 sm:px-6 overflow-hidden transition-all duration-300 ease-in-out ${showMenu ? "block" : "hidden"}`}>
                        <ul className="bg-gray-800 px-4 py-2">
                            {menuItems.map((m, i) => 
                                <li key={`menu-${i}`}>
                                    {m.link && <Link className="flex text-gray-300 hover:text-gray-200 py-2" to={m.link}>{m.title}</Link>}
                                    {m.externalLink && <a href={m.externalLink} className="flex text-gray-300 hover:text-gray-200 py-2">{m.title}</a>}
                                </li>
                            )}
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </header>
}

export default Menu;