import React from "react";
import LayoutWithHero from "../../layout/LayoutWithHero";
import Section from "../../components/Section";
import CardContainer from "../../components/CardContainer";
import Card from "../../components/Card";
import VideoModal from "../../components/VideoModal";
import { useLocation } from "react-router";
import queryString from "qs";

const videos = [
    {
        title: "How To Do It",
        videoId: "906974584",
        runTime: "5:27",
        image: require("../../assets/video_thumbs/project-video.jpg"),
        url: "howToDoIt"
    },
    {
        title: "Part 1",
        videoId: "907106407",
        runTime: "3:21",
        image: require("../../assets/video_thumbs/main-video.jpg"),
        url: "part1"
    },
    {
        title: "Steps 1 - 4",
        videoId: "907106087",
        runTime: "5:56",
        image: require("../../assets/video_thumbs/step-1-4.jpg"),
        url: "steps1to4"
    },
    {
        title: "Part 2 - Realisations",
        videoId: "907105081",
        runTime: "5:46",
        image: require("../../assets/video_thumbs/step-5.jpg"),
        url: "realisations"
    },
    {
        title: "The Full Video",
        videoId: "907093622",
        runTime: "20:53",
        image: require("../../assets/video_thumbs/full-video.jpg"),
        url: "fullVideo"
    },
    {
        title: "Introduction",
        videoId: "906974531",
        runTime: "0:45",
        image: require("../../assets/video_thumbs/trailer.jpg"),
        url: "intro"
    },
];

const VideoPage = () => {
    const location = useLocation();
    const queryArgs = queryString.parse(location.search.slice(1));
    const id = (Array.isArray(queryArgs.id) ? queryArgs.id[0] : queryArgs.id) as string|undefined;
    const [videoToShow, setVideoToShow] = React.useState<string|undefined>(id ? videos.find(v => v.url === id)?.videoId : undefined);
    return <LayoutWithHero title="Videos" subtitle="Explore individual segements of the Virtual Work Experiences video, or watch The Full Video.">
        <Section>
            <CardContainer>
                {videos.map((v) => 
                    <Card 
                        key={`video-${v.videoId}`} 
                        title={v.title} 
                        subtitle={`Runtime: ${v.runTime}`}
                        image={v.image} 
                        buttonText="Watch Video"
                        onClick={()=>setVideoToShow(v.videoId)} />
                )}
            </CardContainer>
            {videoToShow && <VideoModal show={videoToShow !== undefined} onHide={()=>setVideoToShow(undefined)} vimeoId={videoToShow} />}
        </Section>
    </LayoutWithHero>
}

export default VideoPage;