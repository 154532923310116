import React from "react";

type Props = {
    items: string[]
}

const TimelineItem : React.FC<{step:number, item:string, last?:boolean}> = ({step, item, last}) =>
    <div className="relative py-4 md:py-6 pl-24 aos-init aos-animate" data-aos="fade-up" data-aos-anchor="[data-aos-id-timeline]">
        <div className="pl-2">
            <div className="flex items-center mb-3">
                <div className="absolute left-0 inline-flex text-sm font-semibold py-1 px-3 text-green-600 bg-green-200 rounded-full">Step {step}</div>
                {!last && <div className="absolute left-0 h-full px-px bg-gray-800 ml-20 self-start transform -translate-x-1/2 translate-y-3" aria-hidden="true"></div>}
                <div className="absolute left-0 w-2 h-2 bg-purple-600 border-4 box-content border-gray-900 rounded-full ml-20 transform -translate-x-1/2" aria-hidden="true"></div>
            </div>
            <p className="text-lg  -mt-6">{item}</p>
        </div>
    </div>


const Timeline : React.FC<Props> = ({items}) => {
    return <div className="max-w-3xl mx-auto -my-4 md:-my-6 text-left" data-aos-id-timeline="">
        {items.map((item, i) => <TimelineItem key={`step-${i}`} step={i+1} item={item} last={i === (items.length-1)} />)}
    </div>
}

export default Timeline;