import React, { PropsWithChildren, ReactNode } from "react";
import Menu from "../Menu";
import FlowDecorations from "./FlowDecoration";
import Footer from "../Footer";

type Props = {
    title:string, 
    subtitle?:string|string[], 
    hero?:ReactNode
}

const LayoutWithHero : React.FC<PropsWithChildren<Props>> = ({children, title, hero, subtitle}) => {
    React.useEffect(() => { window.scrollTo(0, 0) }, []);
    return <div className="flex flex-col min-h-screen overflow-hidden">
        <Menu />
        <main className="grow">
            <FlowDecorations /> 
            <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
                <div className="relative pt-24 pb-3 md:pt-32 md:pb-4">
                    <div className="max-w-3xl mx-auto text-center md:pb-2">
                        <h1 className="h1 mb-4 aos-init aos-animate" data-aos="fade-up">{title}</h1>
                        {subtitle && (!Array.isArray(subtitle) ? [subtitle] : subtitle).map((s, i) => 
                            <p key={`subtitle-${i}`} className="text-xl text-gray-400 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">{s}</p>
                        )}
                    </div>
                </div>
            </div>
            {hero && <div className="mx-auto">{hero}</div>}
            <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
                <div className="relative pb-12 md:pb-20">
                    {children}
                </div>
            </div>
        </main>
        <Footer />
    </div>
}

export default LayoutWithHero;