import { Link } from "react-router-dom";
import { menuItems } from "../Menu";

const Footer : React.FC = () =>
    <footer>
        <div className="py-12 md:py-16 border-t border-gray-800">
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="grid md:grid-cols-12 gap-8 lg:gap-20 mb-8 md:mb-12">
                    <div className="md:col-span-6 lg:col-span-8">
                        <div className="mb-2">
                            <Link className="inline-block" to="/">
                                <img height={32} src={require("../../assets/logo.svg").default} alt="Virtual Work Experiences" />
                            </Link>
                        </div>
                        <div className="text-gray-400 mt-4">
                            Virtual Work Experiences is a career program from the developers of <a className="underline text-purple-600" href="https://jobjump.com.au">JobJump</a>,
                            a careers website used by over 800 careers practtioners in 500 Australian secondary schools. Virtual Work 
                            Experiences is a more natural, developmental and reliable methodolgy for a student's Careers exploration. 
                            Placing students in the driving seat of their own career formulation in a fun and enriching journey.
                        </div>
                    </div>
                    
                    <div className="md:col-span-1 lg:col-span-2 md:col-start-11 lg:col-start-10">
                        <div className="text-sm ml-auto">
                            <ul>
                                {menuItems.map((m, i) => <li className="mb-1" key={`footer-${i}`}>
                                    {m.link && <Link className="text-gray-400 font-semibold hover:text-gray-100 transition duration-150 ease-in-out" to={m.link}>{m.title}</Link>}
                                    {m.externalLink && <a className="text-gray-400 font-semibold hover:text-gray-100 transition duration-150 ease-in-out" href={m.externalLink}>{m.title}</a>}
                                </li>)}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="md:flex md:items-center md:justify-between">
                    <div className="text-gray-400 text-sm mr-4">© JobJump Pty Ltd. All rights reserved.</div>
                </div>

            </div>
        </div>
    </footer>

export default Footer;