import React from "react";
import DefaultLayout from "../../layout/LayoutWithHero";
import Section, { SectionWithImage } from "../../components/Section";
import Button from "../../components/Button";
import { useNavigate } from "react-router";
import VideoModal from "../../components/VideoModal";

const HomepageSectionHeading : React.FC<{title:string, subtitle?:string}> = ({title, subtitle}) =>
    <React.Fragment>
        {subtitle && <div className="font-architects-daughter text-xl text-purple-600 mb-2">{subtitle}</div>}
        <h2 className="h2 mb-3">{title}</h2>
    </React.Fragment>

const CallToActionButtons : React.FC<{projectText?:string, openVideo:()=>void}> = ({projectText, openVideo}) => {
    const navigate = useNavigate();
    return <div className="flex gap-3">
        <Button className="mt-6" onClick={()=>openVideo()}>Watch the Video</Button>
        <Button className="mt-6" onClick={()=>navigate("/experiences")}>{projectText ?? "Get Started"}</Button>
    </div>
}

export const ImageWrapper : React.FC<{imgUrl:string, alt:string}> = ({imgUrl, alt}) => 
    <div className="relative block group aos-init aos-animate" data-aos="fade-right" data-aos-delay="200">
        <div className="absolute inset-0 bg-gray-800 hidden md:block transform md:translate-y-2 md:translate-x-4 xl:translate-y-4 xl:translate-x-8 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out pointer-events-none" aria-hidden="true"></div>
        <figure className="relative h-0 pb-9/16 md:pb-3/4 lg:pb-9/16 overflow-hidden transform md:-translate-y-2 xl:-translate-y-4 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out">
            <img className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={imgUrl} width="540" height="303" alt={alt} />
        </figure>
    </div>


const Homepage : React.FC<{}> = () => {
    const parentSection = React.useRef<HTMLElement>(null);
    const studentSection = React.useRef<HTMLElement>(null);
    const teacherSection = React.useRef<HTMLElement>(null);
    const theorySection = React.useRef<HTMLElement>(null);
    const [showVideoModal, setShowVideoModal] = React.useState(false);
    const navigate = useNavigate();
    const Hero = <React.Fragment>
        <Section>
            <div className="grid gap-2 md:grid-cols-3 md:gap-5 items-center w-1/2 mx-auto">
                <Button className="mt-6" onClick={()=>parentSection.current?.scrollIntoView()}>For Parents</Button>
                <Button className="mt-6" onClick={()=>studentSection.current?.scrollIntoView()}>For Students</Button>
                <Button className="mt-6" onClick={()=>teacherSection.current?.scrollIntoView()}>For Teachers</Button>
            </div>
        </Section>
        <div>
            <div className="relative flex justify-center items-center aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                <video className="mx-auto w-full" autoPlay loop muted>
                    <source type="video/mp4" src={require("../../assets/loop.mp4")} />
                </video>
            </div>
        </div>
    </React.Fragment>
    return <DefaultLayout title="Virtual Work Experiences" subtitle={["The best and most natural way to find your future career.","A totally free service. Australia, New Zealand, UK, USA."]} hero={Hero}>
        <SectionWithImage image={<ImageWrapper imgUrl={require("../../assets/homepage/parent.jpg")} alt="For Parents" />} ref={parentSection}>
            <HomepageSectionHeading title="For Parents" />
            <p className="p">Research has shown that the destinations for the majority of young people have been laid as early 
                as age 12.</p>
            <p className="p">You owe it to your child to help them get the right guidance and support for their future career. 
                And it can start as early as you wish.</p>
            <p className="p">Our brief video is one of the the most important videos you and your child will ever watch for their career development and will help you get 
                started.</p> 
            <CallToActionButtons openVideo={()=>setShowVideoModal(true)} />
        </SectionWithImage>
        <SectionWithImage imageLhs image={<ImageWrapper imgUrl={require("../../assets/homepage/students.jpg")} alt="For Students" />} ref={studentSection}>
            <HomepageSectionHeading title="For Students" />
            <p className="p">When you begin your Virtual Work Experiences activity, it's like a fun game. The best part is it 
                keeps getting better the more you do!</p>
            <p className="p">Watch our video to get started. You will see in the video, how a person used their Virtual Work Experiences activity to 
                land one of the best jobs in their career, beating over 100 other applicants!</p>
            <CallToActionButtons openVideo={()=>setShowVideoModal(true)} />
        </SectionWithImage>
        <SectionWithImage image={<ImageWrapper imgUrl={require("../../assets/homepage/teacher.jpg")} alt="For Teachers" />} ref={teacherSection}>
            <HomepageSectionHeading title="For Teachers" />
            <p className="p">There are 110+ Virtual Work Experiences to select from.</p>
            <p className="p">Based on the <button className="underline text-purple-600" onClick={()=>theorySection?.current?.scrollIntoView()}>Theory of the Career Game</button>, these experiences give your students:
                <ul className="list-disc pl-10">
                    <li>Highly enjoyable career activities.</li>
                    <li>Suitable for ages 12 to adult.</li>
                    <li>For students of all levels of ability.</li>
                    <li>Improve student performance and commitment across their other studies.</li>
                    <li>Students receive a Certificate detailing their achievements.</li>
                    <li>Employer Reference form provided for student's completed project.</li>
                    <li>Comes with supportive Worksheet for the students' current and 
                        future planning needs.</li>
                    <li>Most careers are able to be easily modified for students from a First Nations 
                        background and students from diverse cultural backgrounds. Support provided.</li>
                    <li>The video includes troubleshooting guidance to help support them 
                        into adulthood.</li>
                </ul>
            </p>
            <p className="p">This video and the accompanying Virtual Work Experiences activity are a life changing experience for you to provide to your students.</p>
            <p className="p"><strong>NOTE</strong>: It is recommended you present this video and this website to your parents, 
                such as on a Parent Teacher Night.</p>
            <CallToActionButtons projectText="See the Activities" openVideo={()=>setShowVideoModal(true)} />
        </SectionWithImage>
        <Section className="py-12 md:py-20 border-t border-gray-800" ref={theorySection}>
            <HomepageSectionHeading title="Theory of the Career Game" />
            <div className="text-left mt-8">
                <p className="p">There are countless examples of people who have 'discovered' their future vocation when they were quite young, 
                    as a result of a chance life changing experience or a fostered approach. It spurred them on to do naturally fun, game-style 
                    activities. These processes are demonstrated from an early age and include, exploration, asking questions, trial and error, 
                    collecting, making and constructing, testing boundaries, opening things up to see what is inside, to name a few. 
                    Virtual Work Experiences uses these methods and is more natural and developmental in the career decision making process.</p>
                <p className="p">These game-style methods have largely been abandoned within the formal Career Education environment.</p>
                <p className="p">
                    <span className="font-bold">The Theory of the Career Game is applied with Virtual Work Experiences</span> and provides the seeds that, 
                    once sown, will enrich the student over time and well into their lifetime in their chosen career.</p>
                <p className="p">
                    <span className="font-bold">Over the past 70 plus years, psychological testing has held sway.</span> It is based on the premise that 
                    if you answer the questions, you will be delivered an answer and a clearer idea of your future range of career options. This approach 
                    removes and relinquishes the key person, the student, from responsibility and the point of centrality. <span className="font-bold">With Virtual Work Experiences, 
                    the student is central and driving their own career formation.</span></p>
                <p className="p">
                    Psychologically, young people around the ages of 14, 15, 16 develop a stronger ability and perception to focus on their 
                    anticipated post high school life (and this is when most psychological testing is done). This is not to be confused with 
                    and is completely different to a student's ability and desire to explore possible careers that may suit their natural talents 
                    and interests. Such exploratory activities can and should be initiated much earlier.</p>
                <p className="p">
                    A salient reminder is that research has also shown that the destinations for the majority of young people have been laid as 
                    early as age 12.</p>
                <p className="p"><span className="font-bold">Virtual Work Experiences are distinguished by a number of key factors.</span>
                    <ul className="list-disc ml-10">
                        <li>Virtual Work Experiences provide educational delivery using open ended activities. This allows a student to 
                            continually extend their project activities over time. It is not based on a set of narrow activities that are 
                            an end in themselves and that are often very prescriptive.</li>
                        <li>Virtual Work Experiences are able to be enjoyed by students at nearly all levels of ability. It therefore 
                            provides for greater educational equity.</li>
                        <li>The purpose of Virtual Work Experiences is to provide a long-lasting desire to explore and experiment in 
                            the student's chosen career. It is not meant to turn them into mini/semi professionals for their chosen career. </li>
                    </ul>
                </p>
            </div>
        </Section>
        <VideoModal onHide={()=>setShowVideoModal(false)} show={showVideoModal} vimeoId="916348510">
            <div className="flex justify-center">
                <Button className="mx-auto mt-6" onClick={()=>navigate("/videos")}>Watch The Videos</Button>
            </div> 
        </VideoModal>
    </DefaultLayout>;
}

export default Homepage;