import React from "react";

const LoadingCard : React.FC = () =>
    <article className="flex flex-col h-full animate-pulse">
        <a className="block" href="#0">
            <figure className="relative h-0 pb-9/16 overflow-hidden">
                <div className="absolute inset-0 w-full h-full bg-slate-700" />
            </figure>
        </a>
        <div className="grow flex flex-col h-full p-6 bg-gray-800">
            <div className="h-5 bg-slate-700 rounded"></div>
            <div>
                <button className="btn-sm text-white bg-purple-600 hover:bg-purple-700 mt-6">
                    <div className="h-3 w-20 bg-slate-400 rounded"></div>
                    <svg className="w-3 h-3 fill-current text-purple-400 shrink-0 ml-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 5H0v2h6v4l6-5-6-5z"></path>
                    </svg>
                </button>
            </div>
        </div>
    </article>

export default LoadingCard;