import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  createBrowserRouter,
  RouterProvider,
  useMatch,
} from "react-router-dom";
import Homepage from './pages/homepage';
import CareerSelect from './pages/career_select';
import ProjectDetails from './pages/project';
import VideoPage from './pages/videos';
import About from './pages/about';
import CareersAdvisersPage from './pages/careers_advisers';

const appendHotJar = () => {
  const scriptTag = document.createElement("script");
  scriptTag.innerHTML = `(function(h,o,t,j,a,r){
    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    h._hjSettings={hjid:3869822,hjsv:6};
    a=o.getElementsByTagName('head')[0];
    r=o.createElement('script');r.async=1;
    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    a.appendChild(r);
})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
  document.head.appendChild(scriptTag);
}

const HotjarProvider = () => {
  React.useEffect(() => appendHotJar(), []);
  return <React.Fragment></React.Fragment>;
}

const router = createBrowserRouter([
  {
    path: "/",
    Component: () => <Homepage />
  },
  {
    path: "/experiences/:careerId",
    Component: () => {
        const match = useMatch("/experiences/:careerId");
        if (match?.params.careerId && !Number.isNaN(match?.params.careerId)) {
          return <ProjectDetails careerId={Number(match.params.careerId)} />
        }
        return <Homepage />
    } 
  },
  {
    path: "/experiences",
    Component: () => <CareerSelect />
  },
  {
    path: "/videos",
    Component: () => <VideoPage />
  },
  {
    path: "/teachers",
    Component: () => <CareersAdvisersPage />
  },
  {
    path: "/about-us",
    Component: () => <About />
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <HotjarProvider />
    <RouterProvider router={router} />
  </React.StrictMode>
);