import { StyleSheet, Document, Page, View, Text } from '@react-pdf/renderer';
import React, { PropsWithChildren } from "react";
import { UnorderedList, UnorderedListItem } from "./Certificate";

const DocumentGrid : React.FC<PropsWithChildren> = ({children}) => {
    const tableStyle = StyleSheet.create({
        tableWrapper: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap"
        }
    });
    return <View style={tableStyle.tableWrapper}>
        {children}
    </View>;
}

type DocumentCellProps = {
    size: 1|2|3|4|5|6|7|8|9|10|11|12
}

const DocumentCell : React.FC<DocumentCellProps & PropsWithChildren> = ({size, children}) => {
    const documentCellStyle = {
        1 : {
            flex: "0 0 8.33333%"
        },
        2 : {
            flex: "0 0 16.66667%"
        },
        3 : {
            flex: "0 0 25%"
        },
        4 : {
            flex: "0 0 33.33333%"
        },
        5 : {
            flex: "0 0 41.66667%"
        },
        6 : {
            flex: "0 0 50%"
        },
        7 : {
            flex: "0 0 58.33333%"
        },
        8 : {
            flex: "0 0 66.66667%"
        },
        9 : {
            flex: "0 0 75%"
        },
        10 : {
            flex: "0 0 83.33333%"
        },
        11 : {
            flex: "0 0 91.66667%"
        },
        12 : {
            flex: "0 0 100%"
        },
    }
    return <View style={documentCellStyle[size]}>
        {children}
    </View>
}

const RatingSection : React.FC<PropsWithChildren & {title:string}> = ({title, children}) => {
    const style = StyleSheet.create({
        numberWrapper: {
            borderLeft:"1px solid #334155", 
            borderBottom:"1px solid #334155", 
            display:"flex", 
            flexDirection:"row"
        },
        numberContainer: {
            borderRight:"1px solid #334155", 
            padding:"4px 4px 4px 4px",
            flex: "0 0 20%",
            textAlign:"center"
        }
    });
    return <View style={{borderTop:"1px solid #334155", borderBottom:"1px solid #334155"}}>
        <DocumentGrid>
            <DocumentCell size={9}><Text style={{paddingLeft:"8px", paddingTop:"8px", fontWeight:"bold"}}>{title}</Text></DocumentCell>
            <DocumentCell size={3}>
                <View style={style.numberWrapper}>
                    <Text style={style.numberContainer}>1</Text>
                    <Text style={style.numberContainer}>2</Text>
                    <Text style={style.numberContainer}>3</Text>
                    <Text style={style.numberContainer}>4</Text>
                    <Text style={style.numberContainer}>5</Text>
                </View>
            </DocumentCell>
        </DocumentGrid>
        {children}
    </View>
}

const genereateReference = (studentName:string, items:string[]) => {
    const style = StyleSheet.create({
        page: {
            lineHeight: 1.5,
            padding: "32px 32px 32px 32px",
            fontSize: 12,
        },
        heading: {
            fontSize: 18,
            textAlign: "center",
            fontWeight:"bold",
            lineHeight: 2
        },
        subheading: {
            fontSize: 16,
            fontWeight:"bold",
            textAlign: "center",
            lineHeight: 2.5,
            marginTop:"16px"
        },
        bold : {
            fontWeight: "bold"
        },
        lineBox: {
            borderBottomColor: "#1A1A1A",
            borderStyle:"dotted",
            borderBottomWidth: 2,
            marginRight: "8px",
            height: 36 
        },
        borderedSection : {
            borderRadius:"3px", 
            border:"1px solid #334155"
        },
        borderedSectionHeading: {
            backgroundColor:"#e2e8f0", 
            borderBottom:"1px solid #334155", 
            padding:"8px 8px 8px 8px",
            fontWeight: "bold"
        }
    });
    return <Document>
        <Page wrap size={"A4"} style={style.page}>
            <View>
                <Text style={style.heading}>Virtual Work Experiences Reference - {studentName}</Text>
                {items.length > 0 && 
                    <View style={[style.borderedSection, {marginTop:"16px"}]}>
                        <Text style={style.borderedSectionHeading}>Virtual Work Experiences Project - Employer Comment</Text>
                        <RatingSection title="">
                            <View style={{padding:"8px 8px 8px 8px"}}>
                                <Text style={{paddingBottom:"8px"}}>{studentName} has completed a project based around their desired career.</Text>
                                <Text style={{paddingBottom:"8px"}}>Ask the student to show you their project, and provide a rating above out of 5.</Text>
                                <Text style={{paddingBottom:"8px"}}>In the project, the student:</Text>
                                <UnorderedList>
                                    {items.map((p,i) => <UnorderedListItem key={`item-${i}`}>{p}</UnorderedListItem>)}
                                </UnorderedList>
                                <Text style={[style.bold, {borderTop:"1px solid #334155", paddingTop:"8px"}]}>Employer Comment:</Text>
                                <View style={style.lineBox}></View>
                                <View style={style.lineBox}></View>
                                <View style={style.lineBox}></View>
                                <View style={style.lineBox}></View>
                            </View>
                        </RatingSection>
                    </View>}
                <View style={[style.borderedSection, {marginTop:"16px"}]}>
                    <Text style={style.borderedSectionHeading}>Employer Details</Text>
                    <View style={{padding:"8px 8px 8px 8px"}}>
                        <DocumentGrid>
                            <DocumentCell size={6}>
                                <View style={style.lineBox}></View>
                            </DocumentCell>
                            <DocumentCell size={6}>
                                <View style={style.lineBox}></View>
                            </DocumentCell>
                        </DocumentGrid>
                        <DocumentGrid>
                            <DocumentCell size={6}>
                                <Text>Employer Name</Text>
                            </DocumentCell>
                            <DocumentCell size={6}>
                                <Text>Employer Signature</Text>
                            </DocumentCell>
                        </DocumentGrid>
                        <DocumentGrid>
                            <DocumentCell size={6}>
                                <View style={style.lineBox}></View>
                            </DocumentCell>
                            <DocumentCell size={6}>
                                <View style={style.lineBox}></View>
                            </DocumentCell>
                        </DocumentGrid>
                        <DocumentGrid>
                            <DocumentCell size={6}>
                                <Text>Company Name</Text>
                            </DocumentCell>
                            <DocumentCell size={6}>
                                <Text>Company Address</Text>
                            </DocumentCell>
                        </DocumentGrid>
                        <DocumentGrid>
                            <DocumentCell size={6}>
                                <View style={style.lineBox}></View>
                            </DocumentCell>
                        </DocumentGrid>
                        <DocumentGrid>
                            <DocumentCell size={6}>
                                <Text>Date</Text>
                            </DocumentCell>
                        </DocumentGrid>
                    </View>
                </View>
            </View>
        </Page>
    </Document>
}

export default genereateReference;