import React from "react";
import DefaultLayout from "../../layout/LayoutWithHero";
import ProjectAPI, { ProjectCareer } from "../../data/Projects";
import CareerSelector from "./CareerSelector";
import { useNavigate } from "react-router";

const CareerSelect : React.FC = () => {
    const [careers, setCareers] = React.useState<ProjectCareer[]>([]);
    const navigate = useNavigate();
    React.useEffect(() => {
        ProjectAPI.careers().then(setCareers);
    }, []);
    return <DefaultLayout title="Your Virtual Work Experiences Activities" subtitle="Find your career below to get started!">
        <CareerSelector careers={careers} onSelect={(id)=>navigate(`/experiences/${id}`)} />
    </DefaultLayout>
}

export default CareerSelect;