import { StyleSheet, Document, Page, View, Text, Font } from '@react-pdf/renderer';
import React, { PropsWithChildren } from 'react';
const Poppins = require("../../assets/fonts/Poppins-Regular.ttf");
const PoppinsBold = require("../../assets/fonts/Poppins-Bold.ttf");
const Italianno = require("../../assets/fonts/Italianno-Regular.ttf");

const styles = StyleSheet.create({
    list: {
      marginBottom: 8,
      marginLeft: 6,
    },
    listItem: {
      marginBottom: 4,
    },
    listItemText: {
      fontSize: 12,
      lineHeight: 1.45,
    },
  });
export const UnorderedList : React.FC<PropsWithChildren & {depth?:number}> = ({ children, depth }) => {
    return <View style={styles.list}>{children}</View>;
  };
export const UnorderedListItem : React.FC<PropsWithChildren> = ({ children }) => {
    return (
      <View style={styles.listItem}>
        <Text style={styles.listItemText}>
          • &nbsp;<Text>{children}</Text>
        </Text>
      </View>
    );
  };

const generateCertificate = (studentName:string, careerName:string, items:string[]) => {
    Font.register({ family: "Italianno", src: Italianno });
    Font.register({ family: "Poppins", src: Poppins });
    Font.register({ family: "Poppins", src: PoppinsBold, fontWeight: "bold" });

    const style = StyleSheet.create({
        page: {
            fontSize: "12pt",
            lineHeight: "1.5",
        },
        borders: {
            marginLeft: "2vw",
            marginRight: "2vw",
            marginTop: "2vh",
            marginBottom: "2vh",
            height:"96vh",
            width:"96vw",
            padding: "32px 32px 32px 32px",
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: "#000000",
        },
        cursiveFontLarge: {
            fontFamily: "Italianno",
            fontSize: "64pt",
            lineHeight: 1.2
        },
        cursiveFont: {
            fontFamily: "Italianno",
            fontSize: "32pt",
            lineHeight: 1.2
        },

        defaultFontFamily: {
            fontFamily: "Poppins",
            fontSize: "24pt",
        },
        headerText: {
            textAlign: "center",
            fontWeight: "bold",
            lineHeight: "2.5",
        },
        defaultFontSize: {
            fontSize: "14pt"
        },
        logo: {
            height:"60px", 
            width:"128px", 
            marginLeft:"auto", 
            marginRight:"auto",
            marginBottom: "16px"
        } 
    });

    return <Document>
        <Page size={"A4"} orientation='landscape' style={style.page}>
            <View style={{...style.borders}}>
                <View style={{...style.headerText, ...style.cursiveFontLarge}}>
                    <Text>Virtual Work Experiences</Text>
                </View>
                <View style={{...style.headerText, ...style.defaultFontFamily}}>
                    <Text>{studentName}</Text>
                </View>
                <View style={{...style.headerText, ...style.cursiveFont}}>
                    <Text>has completed their Virtual Work Experiences project for</Text>
                </View>
                <View style={{...style.headerText, ...style.cursiveFont}}>
                    <Text>{careerName}</Text>
                </View>

                <View style={{...style.defaultFontFamily, ...style.defaultFontSize, marginTop:"32px"}}>
                    <Text style={{marginBottom:"8px"}}>This certificate, along with the presentation of their project shows that {studentName} has:</Text>
                    <UnorderedList>
                        {items.map((i, idx) =>
                            <UnorderedListItem key={`item-${idx}`}>{i}</UnorderedListItem>
                        )}
                    </UnorderedList>
                </View>
                <View style={{...style.defaultFontFamily, ...style.defaultFontSize, textAlign:"right", position:"absolute", bottom:"0", width:"100%"}}>
                    <Text style={{marginBottom:"8px"}}>Date: {new Date(Date.now()).toDateString()}</Text>
                </View>
            </View>
        </Page>
    </Document>
}

export default generateCertificate;