export type ProjectCareer = {
    id: number,
    name: string
}

export type Project = {
    careerName: string,
    title: string,
    currentText: {text:string}[],
    pastText: {text:string}[]
}

const apiKey = "0B882A37-1EAC-4D8C-A2E6-0C1308B12B1B";

const fetchWithApiKey = (input:RequestInfo, init?:RequestInit) =>
    fetch(input, {
        ...init,
        headers: {
            ...init?.headers,
            "X-API-Key": apiKey
        }
    });

const ProjectAPI = {
    careers: () : Promise<ProjectCareer[]> => 
        fetchWithApiKey("https://api.jobjump.com.au/project/careers").then(r => r.json()).catch(() => []),
    project: (id:number) : Promise<Project> =>
        fetchWithApiKey("https://api.jobjump.com.au/project/"+String(id)).then(r => r.json()).catch(() => {return undefined})
};

export default ProjectAPI;