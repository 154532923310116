export type ProjectModificationSuggestions = "firstNations" | "multicultural";

export const ProjectModificationSuggestionsToString : {[k in ProjectModificationSuggestions ]:string} = {
    "firstNations": "First Nations Background",
    "multicultural": "Multicultural Background"
}

type ProjectModificationEntry = {
    [k in ProjectModificationSuggestions]?: string;
};


const firstNationsCommonSuggestions = {
    creative: "When completing your project, you could consider any traditional stories, designs or other traditions of your First Nations community you would like to incorporate into your project.",
    health: "When completing your project, you could consider investigating any particular health issues that impact First Nation peoples.",
    hospitality: "When completing your project, you could investigate any traditional or native ingredients you could incorporate into your creations.",
    animal: "When completing your project, you could investigate animals local to your First Nations community and incorporate these into your work.",
    tourism: "When completing your project, you could consider places of importance to your First Nations community, or local places of beauty and incorporate these.",
    legal: "When completing your project, you could consider legal issues relating to First Nations people in Australia. These could be issues about Land Rights, environmental issues, custody rights or health.",
    environmental: "When completing your project, you could investigate environmental issues relating to your First Nations community, or knowledge your community has about the land.",
    history: "When completing your project, you could investigate and use historical knowledge from your First Nations community. Are there objects or sites of significance you could use as part of your project?"
}

const multiculturalCommonSuggestions = {
    health: "Are there particular health issues that people fom your cultural background often encounter? You could use theses are part of your project.",
    hospitality: "When completing your project, you could consider using ingredients or recipies from your cultural background.",
    creative: "Can you find inspiration from cultural background for your chosen career? Use this in your career project.",
    animal: "What animals are found in your country of origin? You could use these animals in your project.",
    tourism: "What places of importance and beauty are to be found in your country of origin? Use this in your project.",
    history: "What historical knowledge can you find from your culture and counry of origin? Are there objects of special significance you can use in your project?",
    legal: "Are there legal issues that often arise in your counry of origins, or arise in Australia for migrants? Use these in your project.",
    environmental: "What environmental matters are important for your counry of origin. Use these in your project."
}

export const modificationSuggetions : {[k:number]:ProjectModificationEntry} = {
    3: { /* Chef */
        firstNations: firstNationsCommonSuggestions.hospitality,
        multicultural: multiculturalCommonSuggestions.hospitality
    },
    6: { /* Dietitian */
        firstNations: firstNationsCommonSuggestions.health,
        multicultural: multiculturalCommonSuggestions.health
    },
    7: { /* Fashion */
        firstNations: firstNationsCommonSuggestions.creative,
        multicultural: multiculturalCommonSuggestions.creative
    },
    13: { /* Nurse */
        firstNations: firstNationsCommonSuggestions.health,
        multicultural: multiculturalCommonSuggestions.health
    },
    15: { /* Phsyio */
        firstNations: firstNationsCommonSuggestions.health,
        multicultural: multiculturalCommonSuggestions.health
    },
    19: { /* Vet Nurse */
        firstNations: firstNationsCommonSuggestions.animal,
        multicultural: multiculturalCommonSuggestions.animal 
    },
    21: { /* Acting */
        firstNations: firstNationsCommonSuggestions.creative,
        multicultural: multiculturalCommonSuggestions.creative
    },
    22: { /* Animator */
        firstNations: firstNationsCommonSuggestions.creative,
        multicultural: multiculturalCommonSuggestions.creative
    },
    24: { /* Astronomer */
        firstNations: "When completing your project, you could investigate astronomical knowledge from your First Nations community and use it in your project."
    },
    25: { /* Barista */
        firstNations: firstNationsCommonSuggestions.hospitality,
        multicultural: firstNationsCommonSuggestions.hospitality
    },
    35: { /* Environment */
        firstNations: firstNationsCommonSuggestions.environmental,
        multicultural: firstNationsCommonSuggestions.environmental
    },
    36: { /* Events */
        firstNations: firstNationsCommonSuggestions.tourism,
        multicultural: multiculturalCommonSuggestions.tourism
    },
    37: { /* Film Director */
        firstNations: firstNationsCommonSuggestions.creative,
        multicultural: multiculturalCommonSuggestions.creative
    },
    39: { /* Medicine */
        firstNations: firstNationsCommonSuggestions.health,
        multicultural: multiculturalCommonSuggestions.health
    },
    40: { /* Games Dev */
        firstNations: firstNationsCommonSuggestions.creative,
        multicultural: multiculturalCommonSuggestions.creative
    },
    41: { /* Graphic Design */
        firstNations: firstNationsCommonSuggestions.creative,
        multicultural: multiculturalCommonSuggestions.creative
    },
    42: { /* Interior Design */
        firstNations: firstNationsCommonSuggestions.creative,
        multicultural: multiculturalCommonSuggestions.creative
    },
    43: { /* Journalist */
        firstNations: "When completing your project, you could consider writing about issues or events that relate to First Nations people in Australia.",
        multicultural: "Whe completing your project, you could consider writing about issues or events in your country of origin, or about issues important to your cultural background."
    },
    47: { /* Midwife */
        firstNations: firstNationsCommonSuggestions.health,
        multicultural: multiculturalCommonSuggestions.health
    },
    49: { /* Occupational Therapist */
        firstNations: firstNationsCommonSuggestions.health,
        multicultural: multiculturalCommonSuggestions.health
    },
    51 : { /* Park Ranger */
        firstNations: firstNationsCommonSuggestions.environmental,
        multicultural: multiculturalCommonSuggestions.environmental
    },
    52: { /* Photographer */
        firstNations: firstNationsCommonSuggestions.creative,
        multicultural: multiculturalCommonSuggestions.creative
    },
    59: { /* Social Worker */
        firstNations: firstNationsCommonSuggestions.health,
        multicultural: multiculturalCommonSuggestions.health
    },
    61: { /* Vet */
        firstNations: firstNationsCommonSuggestions.animal,
        multicultural: multiculturalCommonSuggestions.animal
    },
    62: { /* Zoo */
        firstNations: firstNationsCommonSuggestions.animal,
        multicultural: multiculturalCommonSuggestions.animal
    },
    64: { /* Animal Attendant */
        firstNations: firstNationsCommonSuggestions.animal,
        multicultural: multiculturalCommonSuggestions.animal
    },
    66: { /* Archeologist */
        firstNations: firstNationsCommonSuggestions.history,
        multicultural: multiculturalCommonSuggestions.history
    },
    71: { /* Chrio */
        firstNations: firstNationsCommonSuggestions.health,
        multicultural: multiculturalCommonSuggestions.health
    },
    82: { /* Translator */
        firstNations: "When completing your project, you could investigate the traditional language of your First Nations community and incorporate this into you project.",
        multicultural: "Investigate the language of your cultural backround and use this in your project."
    },
    88: { /* Medical Sci */
        firstNations: firstNationsCommonSuggestions.health,
        multicultural: multiculturalCommonSuggestions.health
    },
    101: { /* Concierge */
        firstNations: firstNationsCommonSuggestions.tourism,
        multicultural: multiculturalCommonSuggestions.tourism
    },
    102: { /* Historian */
        firstNations: firstNationsCommonSuggestions.history,
        multicultural: multiculturalCommonSuggestions.history
    },
    104: { /* Museum */
        firstNations: firstNationsCommonSuggestions.history,
        multicultural: multiculturalCommonSuggestions.history
    },
    105: { /* Music */
        firstNations: firstNationsCommonSuggestions.creative,
        multicultural: multiculturalCommonSuggestions.creative
    },
    108: { /* Writer */
        firstNations: firstNationsCommonSuggestions.creative,
        multicultural: multiculturalCommonSuggestions.creative
    },
    111: { /* Camera Operator */
        firstNations: firstNationsCommonSuggestions.creative,
        multicultural: multiculturalCommonSuggestions.creative
    },
    112: { /* Dance */
        firstNations: firstNationsCommonSuggestions.creative,
        multicultural: multiculturalCommonSuggestions.creative
    },
    118: { /* Lawyer */
        firstNations: firstNationsCommonSuggestions.legal,
        multicultural: multiculturalCommonSuggestions.legal
    },
    120: { /* Travel */
        firstNations: firstNationsCommonSuggestions.tourism,
        multicultural: multiculturalCommonSuggestions.tourism
    },
    121: { /* Counsellor */
        firstNations: firstNationsCommonSuggestions.health,
        multicultural: multiculturalCommonSuggestions.health
    },
    124: { /* Law Clerk */
        firstNations: firstNationsCommonSuggestions.legal,
        multicultural: multiculturalCommonSuggestions.legal
    },
    127: { /* Farming */
        firstNations: firstNationsCommonSuggestions.environmental,
        multicultural: multiculturalCommonSuggestions.environmental
    },
    128: { /* Artist */
        firstNations: firstNationsCommonSuggestions.creative,
        multicultural: multiculturalCommonSuggestions.creative
    },
    130: { /* Psychology */
        firstNations: firstNationsCommonSuggestions.health,
        multicultural: multiculturalCommonSuggestions.health
    },
    131: { /* Digital Media */
        firstNations: firstNationsCommonSuggestions.creative,
        multicultural: multiculturalCommonSuggestions.creative
    },
    132: { /* Nurse Enrolled */
        firstNations: firstNationsCommonSuggestions.health,
        multicultural: multiculturalCommonSuggestions.health
    },
    133: {
        firstNations: "Are there sports and past times enjoyed by your First Nations community? You could incorporate these into your project.",
        multicultural: "Are there sports and past times enjoyed in your counry of origin? Use these in your project."
    }

}