import React, { PropsWithChildren, ReactNode } from "react";

type SectionProps = PropsWithChildren & {className?:string, border?:boolean};
const Section = React.forwardRef<HTMLElement, SectionProps>(({children, className, border}, ref) => {
    const sectionClassName = `max-w-5xl mx-auto text-center pb-12 md:pb-16 ${border ? "border-t border-gray-800 py-12 md:py-20" : ""} ${className ?? ""}`
    return <section className={sectionClassName} ref={ref}>
        {children}
    </section>
})


type SectionWithImageProps = PropsWithChildren<{image?:ReactNode, imageLhs?:boolean, noBorder?:boolean}>
export const SectionWithImage = React.forwardRef<HTMLElement, SectionWithImageProps>(({image, children, imageLhs, noBorder}, ref) =>
    <Section border={!noBorder} ref={ref}>
        <div className="grid gap-20">
            <div className={`md:grid md:grid-cols-12 ${imageLhs ? "md:gap-16" : "md:gap-6"} items-center`}>
                <div className={`max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 ${!imageLhs ? "md:order-1" : ""}`}>
                    {image}
                </div>
                <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 text-left">
                    {children}
                </div>
            </div>
        </div>
    </Section>);

export default Section;