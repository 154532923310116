import React from "react";

type Props = {
    image?: string,
    title: string,
    subtitle?:string,
    onClick: ()=>void,
    buttonText: string
}

const Card : React.FC<Props> = ({image, title, subtitle, onClick, buttonText}) =>
    <article className="flex flex-col h-full aos-init aos-animate" data-aos="fade-up">
        <a className="block" href="#0">
            {image && <figure className="relative h-0 pb-9/16 overflow-hidden">
                <img className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" src={image} width="352" height="198" alt={title} />
            </figure>}
        </a>
        <div className="grow flex flex-col h-full p-6 bg-gray-800">
            <h3 className="h4 mb-2">
                <span className="hover:text-gray-100 transition duration-150 ease-in-out">{title}</span>
            </h3>
            {subtitle && <p className="p text-center text-gray-400">{subtitle}</p>}
            <div>
                <button className="btn-sm text-white bg-purple-600 hover:bg-purple-700 mt-3" onClick={onClick}>
                    <span className="text-sm">{buttonText}</span>
                    <svg className="w-3 h-3 fill-current text-purple-400 shrink-0 ml-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 5H0v2h6v4l6-5-6-5z"></path>
                    </svg>
                </button>
            </div>
        </div>
    </article>

export default Card;