import React from "react";
import LayoutWithHero from "../../layout/LayoutWithHero";
import { SectionWithImage } from "../../components/Section";
import { ImageWrapper } from "../homepage";

const About : React.FC = () => {
    return <LayoutWithHero title="About Us" subtitle="Virtual Work Experiences is a careers program designed to place students at the centre of their career development.">
        <SectionWithImage image={<ImageWrapper imgUrl={require("../../assets/homepage/teacher-helping.jpg")} alt="Teacher helping a student" />}>
            <h3 className="h3 mb-4">A New Careers Program for Schools</h3>
            <p className="p font-bold">What do Mozart, Prof Brian Cox and Michelangelo all have in common?</p>
            <p className="p">They all showed signs from a very early age of developing in the careers for which they became famous.</p>
            <p className="p">Based on this, Virtual Work Experiences was developed.</p>
            <p className="p">The program is totally free to access for schools, students and parents.</p>
            <p className="p">
                Students can select from a range of 112 careers. They do fun, skill based activities in their career that 
                will give a student a sense of purpose for their education and can have the additional benefit of making them perform better 
                in the rest of their studies.
            </p> 
            <p className="p">
                They can begin at any stage in their educational journey from upper primary 
                school or any year in their high school. For students, it's like a fun game. The best part is it keeps getting 
                better the more they do.
            </p>
        </SectionWithImage>
        <SectionWithImage imageLhs image={<ImageWrapper imgUrl={require("../../assets/gary.png")} alt="Gary Grant" />}>
            <h3 className="h3 mb-4">About the Program Developers</h3>
            <p className="p">
                Virtual Work Experiences has been developed by Gary Grant, a Careers Adviser with over 30 years of 
                experience in Australian schools.
            </p>
            <p className="p">Gary has pioneered a wide range of developments in Careers Education.</p>
            <p className="p">
                In 1990 and 1991 he managed the Careers Market for South West Sydney for 20,000 students across 50 High Schools. He was instrumental 
                in Liverpool Boys High being awarded the first Centre of Excellence for Business Partnerships involving Careers educational programs 
                developed with Mercedes Benz, ANZ, Unisys Computers, Telstra and Kimberly Clark to name a few.</p>
            <p className="p">
                He developed and implemented the first senior subject for High Schools incorporating student-led businesses in partnership with 
                Young Achievement and the Institute for International Trade from 1992 to 1994.  
            </p>
            <p className="p">
                He has been interviewed by Andrew Olle on Sydney Radio and appeared on The Today Show (Channel 9) and Today Tonight (Channel 7)
                for his groundbreaking work in Careers Education during the recession years of the early 1990s.
            </p>
            <p className="p">In 1995 Gary was awarded the Minister's Award for Excellence in Teaching for his contribution to Career Education.</p>
            <p className="p">
                He was the NSW State Project Officer for the Department of Education in 2013 for the development and management of the Careers 
                Education website, Seek2B and then pioneered the creation of JobJump from 2014. JobJump supports over 800 Careers Advisers and 
                over 110,000 students in New South Wales high schools annually. Today, roughly 2 out of every 3 schools in New South Wales use 
                JobJump.
            </p>
            <p className="p">
                The Virtual Work Experiences website is based on software developed by Dean McGregor, a Software Engineer with 15 years experience.
                Dean is the prinicple engineer and CTO of JobJump.
            </p>
        </SectionWithImage>

    </LayoutWithHero>
}

export default About;